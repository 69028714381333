const lang = {
    'langOrder': 3,
    'langCode': 'en',
    'langName': 'English',

    /**
    * layouts/HamburgerMenu.js
    */
    'hamburguerMenu.links.stations': 'Gas stations',
    'hamburguerMenu.links.consumptions': 'Consumptions',
    'hamburguerMenu.links.refuelings': 'Refueling',
    'hamburguerMenu.links.invoices': 'Invoices',
    'hamburguerMenu.links.messages': 'Messages',
    'hamburguerMenu.links.account': 'My details',
    'hamburguerMenu.logoutButton': 'Log out',
    'hamburguerMenu.deleteAccountButton': 'Delete account',
    'hamburguerMenu.deleteAccountPopup.title': 'Enter your password to delete your account',
    'hamburguerMenu.deleteAccountPopup.deleteButton': 'DELETE',
    'hamburguerMenu.deleteAccountPopup.privacyLink': 'Terms of use and Privacy Policy',
    'hamburguerMenu.deleteAccountPopup.confirmMessage': 'Are you sure you want to continue?',

    /**
    * layouts/LoggedLayout.js
    */
    'loggedlayout.bottomMenu.stations': 'Gas stations',
    'loggedlayout.bottomMenu.consumptions': 'Consumptions',
    'loggedlayout.bottomMenu.refuelings': 'Refuelings',
    'loggedlayout.bottomMenu.invoices': 'Invoices',
    'loggedlayout.bottomMenu.messages': 'Messages',
    'loggedlayout.bottomMenu.account': 'My details',

    /**
    * pages/Splash.js
    */
    'splash.langselector.label': 'Select language',
    'splash.animation.text1': 'Energy to the best',
    'splash.animation.text2': 'price for your vehicle',
    'splash.mapbutton': 'VIEW GAS STATIONS AND PRICES',
    'splash.loginbutton': 'LOGIN',
    'splash.ask': "Don't have a Plenoil account yet?",
    'splash.createaccountbutton': 'Create account',
    'splash.policylink': 'Terms of Use and Privacy Policy',

    /**
    * pages/Login.js
    */
    'login.recoverpasswordthrottle': 'You tried to request a new password too quickly. Please wait 1 minute to try again.',
    'login.invalidlogin': 'Invalid login details',
    'login.recoverpasswordemailsent': 'We have sent you an email with the instructions to recover your password',
    'login.loginForm.input.user': 'NIF/CIF (no spaces)',
    'login.loginForm.input.password': 'PASSWORD',
    'login.loginForm.loginbutton': 'LOGIN',
    'login.loginForm.lostpasswordButton': 'Forgot password',
    'login.recoverPasswordForm.text1' : 'Enter your NIF/CIF to receive an email with the',
    'login.recoverPasswordForm.text2' : 'instructions to recover your password',
    'login.recoverPasswordForm.usernameInput': 'NIF/CIF',
    'login.recoverPasswordForm.button': 'REQUEST NEW PASSWORD',
    'login.recoverPasswordForm.gobackbutton': 'Go back',
    'login.noaccountask': "Don't have a Plenoil account yet?",
    'login.createaccountbutton': 'Create account',
    'login.privacylink': 'Terms of use and Privacy Policy',

    /**
    * pages/Logout.js
    */
    'logout.text': 'Logging out...',

    /**
    * pages/Register.js
    */
    'register.registerErrorMessage': 'Please fix any errors before continuing',
    'register.genericErrorMessage': 'An error occurred, please try again',
    'register.confirmationMessageResent' : 'Confirmation message resent',
    'register.title.userData': 'User data',
    'register.inputs.name.label': 'Name and surname / Company name',
    'register.inputs.name.placeholder': 'S.L. Company Name',
    'register.inputs.vatnumber.label': 'CIF/NIF',
    'register.inputs.vatnumber.placeholder': 'XXXXXXXXX',
    'register.inputs.email.label': 'Email',
    'register.inputs.email.placeholder': 'email@example.com',
    'register.inputs.repeatemail.label': 'Repeat email',
    'register.inputs.repeatemail.placeholder': 'email@example.com',
    'register.inputs.password.label': 'Password (minimum 6 digits)',
    'register.inputs.password.placeholder': '******',
    'register.title.address': 'Address',
    'register.inputs.phone.label': 'Phone',
    'register.inputs.phone.placeholder': 'XXXXXXXXX',
    'register.inputs.country.label': 'Country',
    'register.inputs.country.placeholder': 'Your country',
    'register.inputs.province.label': 'Province',
    'register.inputs.province.placeholder': 'Your province',
    'register.inputs.city.label': 'City',
    'register.inputs.city.placeholder': 'Your location',
    'register.inputs.address.label': 'Address',
    'register.inputs.address.placeholder': 'C/ Example, 10, 1A',
    'register.inputs.postalcode.label': 'Postal Code',
    'register.inputs.postalcode.placeholder': 'XXXXX',
    'register.title.invoices': 'INVOICES',
    'register.inputs.invoices.porsuministros.label': 'PER SUPPLY',
    'register.inputs.invoices.mensuales.label': 'MONTHLY',
    'register.inputs.invoices.porsuministros.text': 'you will receive an invoice for each refueling',
    'register.inputs.invoices.mensuales.text': 'you will receive a monthly invoice with all refueling',
    'register.title.matriculas': 'Matriculas',
    'register.inputs.matriculas.input.placeholder': 'Enter registration',
    'register.inputs.matriculas.addVehicleButton': 'Add vehicle',
    'register.policy.text1': 'I have read and accept the',
    'register.policy.text2': 'Privacy Policy',
    'register.createAccountButton': 'CREATE ACCOUNT',
    'register.cancelButton': 'Cancel',
    'register.registrationCompleted.title': 'Account created',
    'register.registrationCompleted.text': 'Shortly you will receive an email to verify your account',
    'register.registrationCompleted.verifiedButton': 'Already verified',
    'register.registrationCompleted.resendEmailButton': 'Resend Email',

    /**
    * pages/Account/index.js
    */
    'account.index.title': 'MY DATA',
    'account.index.concentrador.onprogressmessage': 'Update in progress. It will be available shortly. You can continue browsing.',
    'account.index.inputs.name': 'Name and surname / Company name',
    'account.index.inputs.email': 'Email',
    'account.index.inputs.phone': 'Phone',
    'account.index.inputs.address': 'Address',
    'account.index.inputs.province': 'Province',
    'account.index.inputs.city': 'City',
    'account.index.inputs.postalcode': 'Postal Code',
    'account.index.editButton': 'Edit data',
    'account.index.editInvoicingButton': 'Edit Invoicing',
    'account.index.favoriteStation.placeholder': 'Favorite Station',

    /**
    * pages/Account/UserEdit.js
    */
    'account.userEdit.successMessage': 'Data received',
    'account.userEdit.errorMessage': 'Error receiving data',
    'account.userEdit.title': 'USER DATA',
    'account.userEdit.hub.onprogressmessage': 'Update in progress. It will be available shortly. You can continue browsing.',
    'account.userEdit.input.name.label': 'Name and surname / Company name',
    'account.userEdit.input.name.placeholder': 'Company Name SL',
    'account.userEdit.input.phone.label': 'Phone',
    'account.userEdit.input.phone.placeholder': 'XXXXXXXXX',
    'account.userEdit.input.email.label': 'Email',
    'account.userEdit.input.email.placeholder': 'email@example.com',
    'account.userEdit.input.address.label': 'Address',
    'account.userEdit.input.address.placeholder': 'C/ Example, 10, 1A',
    'account.userEdit.input.province.label': 'Province',
    'account.userEdit.input.province.placeholder': 'Your province',
    'account.userEdit.input.city.label': 'City',
    'account.userEdit.input.city.placeholder': 'Your location',
    'account.userEdit.input.postalcode.label': 'Postal Code',
    'account.userEdit.input.postalcode.placeholder': 'XXXXXX',
    'account.userEdit.input.password.label': 'Password',
    'account.userEdit.input.password.placeholder': '******',
    'account.userEdit.saveButton': 'SAVE',
    'account.userEdit.cancelButton': 'CANCEL',

    /**
    * pages/Account/InvoicingEdit.js
    */
    'account.invoicingEdit.successMessage': 'Data received',
    'account.invoicingEdit.errorMessage': 'Error receiving data',
    'account.invoicingEdit.title': 'INVOICING',
    'account.invoicingEdit.hub.onprogressmessage': 'Update in progress. It will be available shortly. You can continue browsing.',
    'account.invoicingEdit.porsuministro.label': 'PER SUPPLY',
    'account.invoicingEdit.porsuministro.text': 'you will receive an invoice for each refueling.',
    'account.invoicingEdit.mensuales.label': 'MONTHLY',
    'account.invoicingEdit.mensuales.text': 'You will receive a monthly invoice with all refuellings.',
    'account.invoicingEdit.vehicles.title': 'Vehicles',
    'account.invoicingEdit.vehicles.placeholder': 'Enter license plate',
    'account.invoicingEdit.vehicles.addButton': 'Add vehicle',
    'account.invoicingEdit.saveButton': 'SAVE',
    'account.invoicingEdit.cancelButton': 'CANCEL',

    /**
    * pages/Consumptions/index.js
    */
    'consumptions.title': 'CONSUMPTIONS',
    'consumptions.mensualReport.thismonth': 'this month',
    'consumptions.mensualReport.liters': 'LITERS',
    'consumptions.mensualReport.lastRefueling': 'Last refueling',
    'consumptions.barsReportFilters.matriculas.all': 'ALL',
    'consumptions.toggleButton.matriculas': 'View by registration',
    'consumptions.toggleButton.resumen': 'View summary',

    /**
    * pages/Consumptions/BarsReport.js
    */
    'consumptions.barsReport.empty': 'There are no consumptions',
    'consumptions.barsReport.totalYear': 'TOTAL ANNUAL CONSUMPTION',

    /**
    * pages/Consumptions/PieReport.js
    */
    'consumptions.pieReport.empty': 'There are no consumptions',

    /**
    * pages/Dashboard/index.js
    */
    'dashboard.link.consumptions': 'CONSUMPTIONS',
    'dashboard.link.refuelings': 'REFUELINGS',
    'dashboard.link.invoices': 'INVOICES',
    'dashboard.link.account': 'MY DATA',

    /**
    * pages/Dashboard/Station.js
    */
    'dashboard.station.station': 'STATION',
    'dashboard.station.favorite': 'FAVORITE',
    'dashboard.station.closest': 'CLOSEST',
    'dashboard.station.mapButton': 'VIEW',
    'dashboard.station.diesel': 'DIESEL',
    'dashboard.station.sp95': 'SP95',

    /**
    * pages/Dashboard/Statistics.js
    */
    'dashboard.statistics.lastRefueling': 'LAST REFUELING',
    'dashboard.statistics.monthSummary': 'MONTH SUMMARY',

    /**
    * pages/Dashboard/UserMeta.js
    */
    'dashboard.userMeta.welcome': 'Welcome',

    /**
    * pages/Invoices/index.js
    */
    'invoices.title': 'INVOICES',
    'invoices.table.date': 'DATE',
    'invoices.table.number': 'NUMBER',
    'invoices.table.amount': 'AMOUNT',
    'invoices.table.invoice': 'INVOICE',
    'invoices.table.empty': 'There are no invoices',
    'invoices.selectAllButton': 'Select All',
    'invoices.unselectButton': 'Unselect',
    'invoices.downloadButton': 'Download invoices',

    /**
    * pages/Messages/index.js
    */
    'messages.title': 'MESSAGES',
    'messages.empty': 'You have no messages yet',
    'messages.loadMoreButton': 'Load More',

    /**
    * pages/Refuelings/index.js
    */
    'refuelings.title': 'CONSUMPTIONS',
    'refuelings.table.date': 'DATE',
    'refuelings.table.station': 'STATION',
    'refuelings.table.amount': 'AMOUNT',
    'refuelings.table.ticket': 'TICKET',
    'refuelings.table.empty': 'No refuelings',

    /**
    * pages/Refuelings/TicketPreview.js
    */
    'refuelings.ticketpreview.downloadButton': 'Download ticket',

    /**
    * pages/Station/index.js
    */
    'station.title': 'OUR GAS STATIONS',
    'station.electriccharger': 'RECHARGE',
    'station.adblue': 'ADBLUE',
    'station.diesel': 'DIESEL',
    'station.sp95': 'SP95',
    'station.mapButton': 'VIEW',
    'station.open24hours': 'Open 24 hours',
    'station.personalized': 'Personalized attention',

    /**
    * pages/StationsList/index.js
    */
    'stationslist.title': 'OUR GAS STATIONS',
	'stationslist.ubication': 'Location',


    /**
    * pages/Wash/index.js
    */
    'wash.mapButton': 'VIEW',
    'wash.backButton': 'back',
    'wash.minutes': 'wash minutes',
    'wash.lavallantas.text1': 'COLD SOFTENED WATER + DETERGENT',
    'wash.lavallantas.text2': 'POSITION THE LANCE 15-20CM FROM THE VEHICLE',
    'wash.lavallantas.title': 'WASH WHEELS',
    'wash.prelavado.text1': 'COLD SOFTEN WATER + NEON FOAM',
    'wash.prelavado.text2': 'POSITION THE LANCE 15-20CM FROM THE VEHICLE',
    'wash.prelavado.title': 'PREWASH',
    'wash.lavado.text1': 'HOT DECALCIFIED WATER + DETERGENT',
    'wash.lavado.text2': 'POSITION THE LANCE 15-20CM FROM THE VEHICLE',
    'wash.lavado.title': 'WASH',
    'wash.aclarado.text1': 'COLD SOFTEN WATER',
    'wash.aclarado.text2': 'POSITION THE LANCE 25-35CM FROM THE VEHICLE',
    'wash.aclarado.title': 'RINSE',
    'wash.superencerado.text1': 'COLD OZMOTIZED WATER + WAX',
    'wash.superencerado.text2': 'POSITION THE LANCE 25-35CM FROM THE VEHICLE',
    'wash.superencerado.title': 'SUPER WAXED',
    'wash.superencerado2.text1': 'COLD OZMOTIZED WATER + WAX',
    'wash.superencerado2.text2': 'POSITION THE LANCE 50CM FROM THE VEHICLE',
    'wash.superencerado2.title': 'SUPER WAX',
    'wash.abrillantado.text1': 'COLD OZMOTIZED WATER',
    'wash.abrillantado.text2': 'POSITION THE LANCE 25-35CM FROM THE VEHICLE',
    'wash.abrillantado.title': 'WASHING',
    'wash.abrillantado2.text1': 'COLD OZMOTIZED WATER',
    'wash.abrillantado2.text2': 'POSITION THE LANCE 50CM FROM THE VEHICLE',
    'wash.abrillantado2.title': 'BURILLANTADO',

    /**
    * pages/Map/index.js
    */
    'map.title': 'OUR GAS STATIONS',
    'map.viewlistbutton': 'STATIONS LIST',

    /**
    * pages/Map/CustomMarker.js
    */
    'map.customMarker.stationButton': 'View Station',
    'map.customMarker.mapButton': 'Get directions',
    'map.customMarker.diesel': 'diesel',
    'map.customMarker.sp95': 'unleaded 95',

    /**
    * pages/Map/TopFilters.js
    */
    'map.topFilters.filterByProvince': 'Filter by province',
}

export default lang;