const lang = {
     'langOrder': 4,
     'langCode': 'pt',
     'langName': 'Português',

     /**
     * layouts/HamburgerMenu.js
     */
     'hamburguerMenu.links.stations': 'Postos de gasolina',
     'hamburguerMenu.links.consumptions': 'Consumos',
     'hamburguerMenu.links.refuelings': 'Reabastecimento',
     'hamburguerMenu.links.invoices': 'Faturas',
     'hamburguerMenu.links.messages': 'Mensagens',
     'hamburguerMenu.links.account': 'Dados',
     'hamburguerMenu.logoutButton': 'Sair',
     'hamburguerMenu.deleteAccountButton': 'Excluir conta',
     'hamburguerMenu.deleteAccountPopup.title': 'Digite sua senha para excluir sua conta',
     'hamburguerMenu.deleteAccountPopup.deleteButton': 'DELETE',
     'hamburguerMenu.deleteAccountPopup.privacyLink': 'Termos de uso e política de privacidade',
     'hamburguerMenu.deleteAccountPopup.confirmMessage': 'Tem certeza que deseja continuar?',

     /**
     * layouts/LoggedLayout.js
     */
     'loggedlayout.bottomMenu.stations': 'Postos de gasolina',
     'loggedlayout.bottomMenu.consumptions': 'Consumos',
     'loggedlayout.bottomMenu.refuelings': 'Reabastecimento',
     'loggedlayout.bottomMenu.invoices': 'Faturas',
     'loggedlayout.bottomMenu.messages': 'Mensagens',
     'loggedlayout.bottomMenu.account': 'Dados',

     /**
     * pages/Splash.js
     */
     'splash.langselector.label': 'Selecionar idioma',
          'splash.animation.text1': 'Energia para o melhor',
          'splash.animation.text2': 'preço do seu veículo',
          'splash.mapbutton': 'VER POSTOS DE COMBUSTÍVEL E PREÇOS',
          'splash.loginbutton': 'LOGIN',
          'splash.ask': 'Ainda não tem uma conta Plenoil?',
          'splash.createaccountbutton': 'Criar conta',
          'splash.policylink': 'Termos de Uso e Política de Privacidade',

     /**
     * pages/Login.js
     */
     'login.recoverpasswordthrottle': 'Você tentou solicitar uma nova senha muito rapidamente. Aguarde 1 minuto para tentar novamente.',
     'login.invalidlogin': 'Detalhes de login inválidos',
     'login.recoverpasswordemailsent': 'Enviamos um e-mail com as instruções para recuperar sua senha',
     'login.loginForm.input.user': 'NIF/CIF (sem espaços)',
     'login.loginForm.input.password': 'SENHA',
     'login.loginForm.loginbutton': 'LOGIN',
     'login.loginForm.lostpasswordButton': 'Esqueci a senha',
     'login.recoverPasswordForm.text1' : 'Introduza o seu NIF/CIF para receber um email com o',
     'login.recoverPasswordForm.text2' : 'instruções para recuperar sua senha',
     'login.recoverPasswordForm.usernameInput': 'NIF/CIF',
     'login.recoverPasswordForm.button': 'SOLICITAR NOVA SENHA',
     'login.recoverPasswordForm.gobackbutton': 'Voltar',
     'login.noaccountask': 'Ainda não tem uma conta Plenoil?',
     'login.createaccountbutton': 'Criar conta',
     'login.privacylink': 'Termos de uso e política de privacidade',

     /**
     * pages/Logout.js
     */
     'logout.text': 'Logout...',

          /**
     * pages/Register.js
     */
     'register.registerErrorMessage': 'Por favor, corrija quaisquer erros antes de continuar',
     'register.genericErrorMessage': 'Ocorreu um erro, tente novamente',
     'register.confirmationMessageResent': 'Mensagem de confirmação reenviada',
     'register.title.userData': 'Dados do usuário',
     'register.inputs.name.label': 'Nome e sobrenome / Nome da empresa',
     'register.inputs.name.placeholder': 'S.L. Nome da empresa',
     'register.inputs.vatnumber.label': 'CIF/NIF',
     'register.inputs.vatnumber.placeholder': 'XXXXXXXXX',
     'register.inputs.email.label': 'E-mail',
     'register.inputs.email.placeholder': 'email@example.com',
     'register.inputs.repeatemail.label': 'Repetir e-mail',
     'register.inputs.repeatemail.placeholder': 'email@example.com',
     'register.inputs.password.label': 'Senha (mínimo 6 dígitos)',
     'register.inputs.password.placeholder': '******',
     'register.title.address': 'Endereço',
     'register.inputs.phone.label': 'Telefone',
     'register.inputs.phone.placeholder': 'XXXXXXXXX',
     'register.inputs.country.label': 'Pais',
     'register.inputs.country.placeholder': 'Seu pais',
     'register.inputs.province.label': 'Província',
     'register.inputs.province.placeholder': 'Sua província',
     'register.inputs.city.label': 'Cidade',
     'register.inputs.city.placeholder': 'Sua localização',
     'register.inputs.address.label': 'Endereço',
     'register.inputs.address.placeholder': 'C/ Exemplo, 10, 1A',
     'register.inputs.postalcode.label': 'Código Postal',
     'register.inputs.postalcode.placeholder': 'XXXXX',
     'register.title.invoices': 'FATURAS',
     'register.inputs.invoices.porsuministros.label': 'POR FORNECIMENTO',
     'register.inputs.invoices.mensuales.label': 'MENSAL',
     'register.inputs.invoices.porsuministros.text': 'você receberá uma fatura para cada reabastecimento',
     'register.inputs.invoices.mensuales.text': 'você receberá uma fatura mensal com todos os abastecimentos',
     'register.title.matrículas': 'Matrículas',
     'register.inputs.matriculas.input.placeholder': 'Digite o registro',
     'register.inputs.matriculas.addVehicleButton': 'Adicionar veículo',
     'register.policy.text1': 'Li e aceito o',
     'register.policy.text2': 'Política de Privacidade',
     'register.createAccountButton': 'CRIAR CONTA',
     'register.cancelButton': 'Cancelar',
     'register.registrationCompleted.title': 'Conta criada',
     'register.registrationCompleted.text': 'Em breve você receberá um e-mail para verificar sua conta',
     'register.registrationCompleted.verifiedButton': 'Já verificado',
     'register.registrationCompleted.resendEmailButton': 'Reenviar e-mail',

     /**
     * pages/Account/index.js
     */
     'account.index.title': 'DADOS',
     'account.index.concentrador.onprogressmessage': 'Atualização em andamento. Em breve estará disponível. Você pode continuar navegando.',
     'account.index.inputs.name': 'Nome e sobrenome / Nome da empresa',
     'account.index.inputs.email': 'E-mail',
     'account.index.inputs.phone': 'Telefone',
     'account.index.inputs.address': 'Endereço',
     'account.index.inputs.province': 'Província',
     'account.index.inputs.city': 'Cidade',
     'account.index.inputs.postalcode': 'Código Postal',
     'account.index.editButton': 'Editar dados',
     'account.index.editInvoicingButton': 'Editar Faturamento',
     'account.index.favoriteStation.placeholder': 'Posto de gasolina favorito',

     /**
     * pages/Account/UserEdit.js
     */
     'account.userEdit.successMessage': 'Dados recebidos',
     'account.userEdit.errorMessage': 'Erro ao receber dados',
     'account.userEdit.title': 'DADOS DO USUÁRIO',
     'account.userEdit.hub.onprogressmessage': 'Atualização em andamento. Em breve estará disponível. Você pode continuar navegando.',
     'account.userEdit.input.name.label': 'Nome e sobrenome / Nome da empresa',
     'account.userEdit.input.name.placeholder': 'Nome da empresa SL',
     'account.userEdit.input.phone.label': 'Telefone',
     'account.userEdit.input.phone.placeholder': 'XXXXXXXXX',
     'account.userEdit.input.email.label': 'E-mail',
     'account.userEdit.input.email.placeholder': 'email@example.com',
     'account.userEdit.input.address.label': 'Endereço',
     'account.userEdit.input.address.placeholder': 'C/ Exemplo, 10, 1A',
     'account.userEdit.input.province.label': 'Província',
     'account.userEdit.input.province.placeholder': 'Sua província',
     'account.userEdit.input.city.label': 'Cidade',
     'account.userEdit.input.city.placeholder': 'Sua localização',
     'account.userEdit.input.postalcode.label': 'Código Postal',
     'account.userEdit.input.postalcode.placeholder': 'XXXXXX',
     'account.userEdit.input.password.label': 'Senha',
     'account.userEdit.input.password.placeholder': '******',
     'account.userEdit.saveButton': 'SALVAR',
     'account.userEdit.cancelButton': 'CANCELAR',

     /**
     * pages/Account/InvoicingEdit.js
     */
     'account.invoicingEdit.successMessage': 'Dados recebidos',
     'account.invoicingEdit.errorMessage': 'Erro ao receber dados',
     'account.invoicingEdit.title': 'INVOICING',
     'account.invoicingEdit.hub.onprogressmessage': 'Atualização em andamento. Em breve estará disponível. Você pode continuar navegando.',
     'account.invoicingEdit.porsuministro.label': 'POR FORNECIMENTO',
     'account.invoicingEdit.porsuministro.text': 'você receberá uma fatura para cada reabastecimento.',
     'account.invoicingEdit.mensuales.label': 'MENSAL',
     'account.invoicingEdit.mensuales.text': 'Você receberá uma fatura mensal com todos os abastecimentos.',
     'account.invoicingEdit.vehicles.title': 'Veículos',
     'account.invoicingEdit.vehicles.placeholder': 'Digite a placa',
     'account.invoicingEdit.vehicles.addButton': 'Adicionar veículo',
     'account.invoicingEdit.saveButton': 'SALVAR',
     'account.invoicingEdit.cancelButton': 'CANCELAR',

     /**
     * pages/Consumptions/index.js
     */
     'consumptions.title': 'CONSUMOS',
     'consumptions.mensualReport.thismonth': 'este mês',
     'consumptions.mensualReport.liters': 'LITROS',
     'consumptions.mensualReport.lastRefueling': 'Último reabastecimento',
     'consumptions.barsReportFilters.matriculas.all': 'Todos',
     'consumptions.toggleButton.matriculas': 'Visualizar por cadastro',
     'consumptions.toggleButton.resumen': 'Visualizar resumo',

     /**
     * pages/Consumptions/BarsReport.js
     */
     'consumptions.barsReport.empty': 'Não há consumos',
     'consumptions.barsReport.totalYear': 'TOTAL DO CONSUMO ANUAL',

     /**
     * pages/Consumptions/PieReport.js
     */
     'consumptions.pieReport.empty': 'Não há consumos',

     /**
     * pages/Dashboard/index.js
     */
     'dashboard.link.consumptions': 'CONSUMOS',
     'dashboard.link.refuelings': 'REABASTECIMENTOS',
     'dashboard.link.invoices': 'FATURAS',
     'dashboard.link.account': 'DADOS',

     /**
     * pages/Dashboard/Station.js
     */
     'dashboard.station.station': 'POSTO DE GASOLINA',
     'dashboard.station.favorite': 'FAVORITO',
     'dashboard.station.closest': 'CLOSEST',
     'dashboard.station.mapButton': 'VER',
     'dashboard.station.diesel': 'DIESEL',
     'dashboard.station.sp95': 'SP95',

     /**
     * pages/Dashboard/Statistics.js
     */
     'dashboard.statistics.lastRefueling': 'ÚLTIMO REABASTECIMENTO',
     'dashboard.statistics.monthSummary': 'RESUMO DO MÊS',

     /**
     * pages/Dashboard/UserMeta.js
     */
     'dashboard.userMeta.welcome': 'Bem-vindo',

     /**
     * pages/Invoices/index.js
     */
     'invoices.title': 'FATURAS',
     'invoices.table.date': 'DATA',
     'invoices.table.number': 'NÚMERO',
     'invoices.table.amount': 'VALOR',
     'invoices.table.invoice': 'FATURA',
     'invoices.table.empty': 'Não há faturas',
     'invoices.selectAllButton': 'Selecionar tudo',
     'invoices.unselectButton': 'Desmarcar',
     'invoices.downloadButton': 'Baixar faturas',

     /**
     * pages/Messages/index.js
     */
     'messages.title': 'MESSAGES',
     'messages.empty': 'Você ainda não tem mensagens',
     'messages.loadMoreButton': 'Carregar mais',

     /**
     * pages/Refuelings/index.js
     */
     'refuelings.title': 'CONSUMOS',
     'refuelings.table.date': 'DATE',
     'refuelings.table.station': 'ESTAÇÃO',
     'refuelings.table.amount': 'VALOR',
     'refuelings.table.ticket': 'TICKET',
     'refuelings.table.empty': 'Sem reabastecimento',

     /**
     * pages/Refuelings/TicketPreview.js
     */
     'refuelings.ticketpreview.downloadButton': 'Baixar ticket',

     /**
     * pages/Station/index.js
     */
     'station.title': 'NOSSOS POSTOS DE GASOLINA',
     'station.electriccharger': 'RECARREGA',
     'station.adblue': 'ADBLUE',
     'station.diesel': 'DIESEL',
     'station.sp95': 'SP95',
     'station.mapButton': 'VER',
     'station.open24hours': 'Aberto 24 horas',
     'station.personalized': 'Atenção personalizada',

     /**
     * pages/StationsList/index.js
     */
     'stationslist.title': 'NOSSOS POSTOS DE GASOLINA',
	'stationslist.ubication': 'Localização',

     /**
     *pages/Wash/index.js
     */
     'wash.mapButton': 'VER',
     'wash.backButton': 'voltar',
     'wash.minutes': 'minutos de lavagem',
     'wash.lavallantas.text1': 'ÁGUA AMACIADA FRIA + DETERGENTE',
     'wash.lavallantas.text2': 'POSICIONE A LANÇA 15-20CM DO VEÍCULO',
     'wash.lavallantas.title': 'LAVAR RODAS',
     'wash.prelavado.text1': 'ÁGUA SUAVE FRIA + ESPUMA NEON',
     'wash.prelavado.text2': 'POSICIONE A LANÇA 15-20CM DO VEÍCULO',
     'wash.prelavado.title': 'PRÉ-LAVAGEM',
     'wash.lavado.text1': 'ÁGUA DESCALCIFICADA QUENTE + DETERGENTE',
     'wash.lavado.text2': 'POSICIONE A LANÇA 15-20CM DO VEÍCULO',
     'wash.lavado.title': 'WASH',
     'wash.aclarado.text1': 'ÁGUA FRIA',
     'wash.aclarado.text2': 'POSICIONE A LANÇA 25-35CM DO VEÍCULO',
     'wash.aclarado.title': 'ENXAGUAR',
     'wash.superencerado.text1': 'ÁGUA OZMOTIZADA FRIA + CERA',
     'wash.superencerado.text2': 'POSICIONE A LANÇA 25-35CM DO VEÍCULO',
     'wash.superencerado.title': 'SUPER CERADO',
     'wash.superencerado2.text1': 'ÁGUA OZMOTIZADA FRIA + CERA',
     'wash.superencerado2.text2': 'POSICIONE A LANÇA A 50CM DO VEÍCULO',
     'wash.superencerado2.title': 'SUPER CERA',
     'wash.abrillantado.text1': 'ÁGUA OZMOTIZADA FRIA',
     'wash.abrillantado.text2': 'POSICIONE A LANÇA 25-35CM DO VEÍCULO',
     'wash.abrillantado.title': 'LAVANDO',
     'wash.abrillantado2.text1': 'ÁGUA OZMOTIZADA FRIA',
     'wash.abrillantado2.text2': 'POSICIONE A LANÇA A 50CM DO VEÍCULO',
     'wash.abrillantado2.title': 'BURILLANTADO',

     /**
     * pages/Map/index.js
     */
     'map.title': 'NOSSOS POSTOS DE GASOLINA',
     'map.viewlistbutton': 'LISTA DE POSTOS DE GASOLINA',

     /**
     * pages/Map/CustomMarker.js
     */
     'map.customMarker.stationButton': 'Visualizar estação',
     'map.customMarker.mapButton': 'Obter direções',
     'map.customMarker.diesel': 'diesel',
     'map.customMarker.sp95': 'SP95',

     /**
     * pages/Map/TopFilters.js
     */
     'map.topFilters.filterByProvince': 'Filtrar por província',
}

export default lang;