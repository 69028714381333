import React, { useState, useEffect } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { openBlob } from 'helpers/generic';
import { useParams, useNavigate } from 'react-router-dom';
import { default as RepostajeService } from 'services/Repostaje';
import { __ } from 'helpers/i18n';

const GlobalStyle = createGlobalStyle` 
	html, body {
		&.ios {
			#preview-wrapper {
				padding-top: 120px !important;
			}

			#close {
				top: 80px !important;
			}
		}
	}
`;

const TicketStyled = styled.div` 
	margin: 0 auto;
	overflow: auto;

	#preview-wrapper {
		box-shadow: var(--plenoil-shadow);
		padding-top: 30px;
		padding-bottom: 20px;

		img {
			width: 100%;
		}
	}

	#close {
		position: absolute;
		top: 10px;
		right: 10px;
		border: 0;
		background:none;
		background: var(--plenoil-blue2);
		color: white;
		border-radius: 50%;
		font-size: 18px;
		line-height: 18px;
		width: 20px;
		height: 20px;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	#btn-download-ticket {
		font-size: 16px;
		/* margin-top: -30px; */
	}

	#ticket-empty {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		color: var(--plenoil-gray2);
		font-weight: 700;
		font-size: 18px;
		width: 100%;
		text-transform: uppercase;

		img {
			display: block;
			width: 120px;
			margin-bottom: 30px;
		}
	}
`;

export default function TicketPreview() {
	let navigate = useNavigate();
	let params = useParams();

	let id = params.id;

	id = id.replaceAll('|-|', '/'); // Fix to prevent problems con url react router

	const [preview, setPreview] = useState(null);

	const closeTicket = () => {
		navigate(-1);
	}

	useEffect(() => {
		let getPreview = async () => {
			let preview = await RepostajeService.getPreview(id);
			setPreview(preview);
		}
		getPreview();
	}, [id])

	const downloadTicket = async () => {
		let result = await RepostajeService.downloadTicket(id);
		// if ( result ) openBlob(result, 'plenoil_ticket_' + data.numero + '.pdf');
		if ( result ) openBlob(result, 'plenoil_ticket.pdf');
	}

	// Render
	return (
		<TicketStyled id="ticket">
			<button id="close" onClick={() => closeTicket()}><i className="bi bi-x"></i></button>

			{ preview &&
				<div id="preview-wrapper">
					<img src={preview} />

					<div className="text-center">
						<button className="btn-plenoil btn-plenoil-blue2" id="btn-download-ticket" onClick={() => downloadTicket()}>{__('refuelings.ticketpreview.downloadButton')}</button>
					</div>
				</div>
			}
		</TicketStyled>
	);
}