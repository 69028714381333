import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import moment from 'moment/min/moment-with-locales';
import LoggedLayout from 'layouts/LoggedLayout';
import { ucfirst, assets, jsonToArray, formatNumber } from 'helpers/generic';
import { default as RepostajeService } from 'services/Repostaje';
import { __, getLanguage } from 'helpers/i18n';

const ContainerStyled = styled.div` 
	position: relative;
`;

const List = styled.div` 
	padding: 15px;

	table {
		th, td {
			&:nth-child(1) {
				width: 80px;
			}
			&:nth-child(2) {
			}
			&:nth-child(3) {
				width: 80px;
				text-align: center;
				white-space: nowrap;
			}
			&:nth-child(4) {
				width: 50px;
				text-align: center;

				a {
					img {
						width: 20px;
					}
				}
			}
		}

		td {
			&:nth-child(2) {
				font-weight: 700;
			}
			&:nth-child(3) {
				font-weight: 700;
			}
		}

		tr:last-of-type {
			border-bottom: 0;
		}
	}

	#empty {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		color: var(--plenoil-gray2);
		font-weight: 500;
		font-size: 16px;
		margin: 80px 10px;

		img {
			display: block;
			width: 120px;
			margin-bottom: 10px;
		}
	}
`;

export default function Refuelings() {
	let [monthSales, setMonthSales] = useState(undefined);
	let [year, setYear] = useState(moment().format('YYYY'));
	let [month, setMonth] = useState(moment().format('MM'));

	useEffect(() => {
		const getData = async () => {
			let monthSales = await RepostajeService.listMonthSales(year, month);
			setMonthSales(monthSales);
		}
		getData();
	}, [year, month]);

	return (
		<React.Fragment>
			<LoggedLayout>
				<ContainerStyled>
					<div className="page-title">{__('refuelings.title')}</div>

					<List>
						<div className="mb-4 d-flex justify-content-center">
							<select className="box-select" value={month ?? ''} onChange={(e) => setMonth(e.target.value)}>
								{(() => {
									let months = [];
									for(let i = 1; i<=12; i++) {
										let tmpMonth = moment('2022-' + (i < 10 ? '0'+i : i ) + '-01').locale(getLanguage()).format('MMMM');
										months.push(<option key={i} value={(i < 10 ? '0'+i : i )}>{ucfirst(tmpMonth)}</option>);
									}
									return months;
								})()}
							</select>
							<select className="box-select" value={year ?? ''} onChange={(e) => setYear(e.target.value)}>
								{(() => {
									let years = [];
									for(let i = 2018; i<=moment().format('YYYY'); i++) {
										years.push(<option key={i} value={i}>{i}</option>);
									}
									return years.reverse();
								})()}
							</select>
						</div>
						
						<table className="box-table">
							<thead>
								<tr>
									<th>{__('refuelings.table.date')}</th>
									<th>{__('refuelings.table.station')}</th>
									<th>{__('refuelings.table.amount')}</th>
									<th>{__('refuelings.table.ticket')}</th>
									<th></th>
									<th></th>
									<th></th>
								</tr>
							</thead>
							<tbody>
								{monthSales && jsonToArray(monthSales).sort((a, b) => {
									// Resort because json key is integer and is showing unordered
									
									let aDate = moment(a.value.date, 'DD/MM/YY').unix();
									let bDate = moment(b.value.date, 'DD/MM/YY').unix();

									if ( aDate < bDate ) return -1;
									if ( aDate > bDate ) return 1;
									return 0;
								}).map((el, idx) => {
									let ticket = el.value;

									let ticketIdFix = ticket.id.replaceAll('/', '|-|'); // Fix to prevent problems con url react router

									return (
										<tr key={idx}>
											<td>{ticket.date.replaceAll('/', '-')}</td>
											<td>{ticket.station?.name}</td>
											<td>{formatNumber(ticket.amount, 2, true, true)}€</td>
											<td>
												<NavLink to={'/refueling/ticket-preview/' + ticketIdFix} className="btn btn-link btn-sm">
													<img src={assets('assets/img/icono-vista-previa.svg')} alt="preview" />
												</NavLink>
											</td>
										</tr>
									)
								})}
							</tbody>
						</table>
						{ (!monthSales || !Object.keys(monthSales).length) &&
							<div id="empty">
								<img src={assets('assets/img/icono-no-repostajes.svg')} alt="empty" />
								{__('refuelings.table.empty')}
							</div>
						}
					</List>

				</ContainerStyled>
			</LoggedLayout>
		</React.Fragment>
	);
}


